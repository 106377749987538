const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_qLYOiddEW",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "2bnpad8g4empbvks76o40i1s7u",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "dev.agsafe.org.au",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
      // CSRF
      sameSite: "lax",
    },
  },
  ApiUrl: {
    Rest: "https://api.dev.agsafe.org.au",
    WebSocket: "https://api.dev.agsafe.org.au",
  },
  Branding: {
    LoginBackground: {
      "dev.agsafe.org.au": "/media/bg/agsafe-bg.jpg",
    },
  },
  CognitoInformation: {
    IssuerName: 'drumDRIVE-DEV'
  }
};

export default config;
